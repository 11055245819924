import React, { useContext } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CustomAuth from "../../components/custom-auth"
import TenantAnalyticEdit from "../../components/tenant-analytic-edit/tenant-analytic-edit"
import { UserContext } from "../../provider/usercontextprovider"
import { InternationalizationProvider } from "../../provider/i18nprovider"
import { UserTenantProvider } from "../../provider/usertenantprovider"

const ProtectedPage = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isAdmin = userContext.username && userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('TenantAdmin');

  return (
        <CustomAuth>
          <UserTenantProvider>
            <InternationalizationProvider>
              <SEO title="Tenant Editor" />
                {isAdmin ? <TenantAnalyticEdit/> : <p>User is not an Admin</p>}
            </InternationalizationProvider>
          </UserTenantProvider>
        </CustomAuth>
  )

}
export default ProtectedPage