import React, { useContext, useEffect, useState } from "react"
import { API, graphqlOperation } from "aws-amplify"
import Select from 'react-select'
import { LoadMaskContext } from "../../provider/loadmaskprovider"
import CodeTranslationEdit from "./code-translation-edit"
import { UserTenantContext } from "../../provider/usertenantprovider"
import { i18nContext, LanguageContext } from "../../provider/i18nprovider"
import * as buttonStyles from '../button.module.css'
import { Notification, NotificationType } from "../notification"

const updateCodeTranslations = /* GraphQL */`
  mutation UpdateCodeTranslations(
    $input: UpdateCodeTranslationsInput!
  ) {
    updateCodeTranslations(input: $input) {
      tenant
      language
      questions {
        id
        question
      }
      analyticResults {
        id
        alias
        answer
        proceed
      }
    }
  }
`

const getTenant = /* GraphQL */`
  query getTenant($name: ID!) {
    getTenant(name:$name) {
      name
      analyticConfiguration {
        resultConfiguration {
          emailAddressesToAlert
          id
          threshold
        }
        questionConfiguration {
          id
          weight
          weightTrigger
        }
      }
      codeTranslations {
        items {
          language
          questions {
            id
            question
          }
        }
      }
    }
  }
`


const flexgrowStyles = {
  container: base => ({
    ...base,
    flexGrow: 1
  })
};

const TenantAnalyticEditComponent = props => {
  const [isLoading, setIsLoading] = useContext(LoadMaskContext)
  const [userTenantContext, setUserTenantContext] = useContext(UserTenantContext);
  const [internationalizationContext, setI18nContext] = useContext(i18nContext)
  const [tenant, setTenant] = useState(null)
  const [codeTranslations, setCodeTranslations] = useState({});
  const [langOptions, setLangOptions] = useState([]);
  const [currentLang, setCurrentLang] = useState(null);
  const [message, setMessage] = useState({
    show: false,
  });

  const options = userTenantContext.filter(t=>t.tenantRole.includes("Admin")).map(tenant=>{
    return {
        value: tenant.tenantObj.name,
        label: tenant.tenantObj.prettyName,
        tenantObj: tenant.tenantObj
    }
  });

  const onTenantChange = async e => {
    setIsLoading(true)
    let result = await API.graphql(
      graphqlOperation(getTenant, {
          name: e.value
      })
    )
    setTenant(result.data.getTenant)
    const opts = Object.keys(internationalizationContext[e.value]).map(lang=>{
      return {
        value: lang,
        label: lang
      }
    })
    setLangOptions(opts)
    setIsLoading(false)
  }
  const onLanguageChange = async e => {
    setCodeTranslations({...internationalizationContext[tenant.name][e.value]})
    setCurrentLang(e.value)
  }

  const onQuestionChange = question => event =>{
    let newCodeTranslations = {...codeTranslations}
    newCodeTranslations['questions'][question.id].question = event.target.value
    setCodeTranslations(newCodeTranslations)
  }

  const onResultChange = (result) => event =>{
    let newCodeTranslations = {...codeTranslations}
    newCodeTranslations['analyticResults'][result.id].answer = event.target.value
    setCodeTranslations(newCodeTranslations)
  }

  const save = async () => {    
    setIsLoading(true)
    try {
        await API.graphql(
            graphqlOperation(updateCodeTranslations, {
                input:{
                tenant: tenant.name,
                language: currentLang,
                questions: Object.values(codeTranslations['questions']),
                analyticResults: Object.values(codeTranslations['analyticResults'])
                }
            })
        );
        setMessage({
            show: true,
            msg: 'Successfully updated',
            messageType: NotificationType.SUCCESS,
        });
        setTimeout(() => {
            setMessage({ show: false })
        }, 2000);
        let newContext = {...internationalizationContext}
        newContext[tenant.name][currentLang] = codeTranslations
        setI18nContext(newContext)
    } catch (error) {
        setMessage({
            show: true,
            msg: 'Failed to update',
            messageType: NotificationType.FAIL,
        });
        console.log(error);
    } finally {
        setIsLoading(false)
    }
    
  }

  return (
    <>
        <h1>Tenant</h1>

        {message.show &&
            <div style={{ marginBottom: "1rem" }}>
                <Notification messageType={message.messageType}>
                    <p>{message.msg}</p>
                </Notification>
            </div>
        }

        <div style={{display:"flex", flexDirection:"row"}}>
            <Select placeholder="Choose Tenant" styles={flexgrowStyles} options={options} onChange={onTenantChange} />
            <Select placeholder="Choose Language" styles={flexgrowStyles} options={langOptions} onChange={onLanguageChange} />
        </div>
        {currentLang && 
        <div style={{ marginTop: "1rem" }}>
            <h2>Questions</h2>
            <div>
            {tenant.analyticConfiguration.questionConfiguration.map(question=>{
                return(<CodeTranslationEdit key={question.id} onChange={onQuestionChange(question)} value={codeTranslations['questions'][question.id]}/>)
            })}
            </div>
            <h2>Results</h2>
            <div>
            {tenant.analyticConfiguration.resultConfiguration.map(result=>{
                return(<CodeTranslationEdit key={result.id} onChange={onResultChange(result)} value={codeTranslations['analyticResults'][result.id]}/>)
            })}
            </div>
            <button onClick={save} style={{display:'block',margin:'auto'}} className={buttonStyles.button} type="submit">Submit Changes</button>
        </div>
        }
    </>
  )
}

export default TenantAnalyticEditComponent
