import React from "react"

const CodeTranslationEdit = props => {
  return (
    <>
      <div style={{ marginBottom: "5px" }}>ID: {props.value.id}</div>
      <textarea
        value={props.value.question ? props.value.question : props.value.answer}
        onChange={props.onChange}
        style={{
          display: "block",
          width: "100%",
          height: "8rem",
          fontFamily: "inherit",
          marginBottom: "10px",
          borderRadius: "0.375rem",
          border: "1px solid rgb(204,204,204)",
          padding: "0.625rem",
          boxSizing: "border-box"
        }}
      />
    </>
  )
}

export default CodeTranslationEdit
